export default class TreeView {

    constructor($view) {
        this.view = $view;

        this.selectedItem;
        this.treeview;

        this.init();
    }

    init() {
        let self = this;

        // On map le contexte des functions sur l'instance
        self.add = self.add.bind(self);
        self.getInfo = self.getInfo.bind(self);
        self.delete = self.delete.bind(self);
        self.download = self.download.bind(self);

        self._selectByNode = self._selectByNode.bind(self);
        self._getById = self._getById.bind(self);
        self._selectById = self._selectById.bind(self);
        self._getSelected = self._getSelected.bind(self);
        self._refreshTreeView = self._refreshTreeView.bind(self);
        self._getParentFolder = self._getParentFolder.bind(self);
        self._checkedNodeIds = self._checkedNodeIds.bind(self);

        $(function () {
            self.treeview = $('#treeview').data('kendoTreeView');

            $(document).on('click', '.js-delete', (e) => self.delete(e));

            var id = self.view.data('path');
            self._refreshTreeView(id);
        });

        /**
        * Solution temporaire pour gérer l'affichage d'un toast pour informer du succès de MAJ sur 'OnSuccess = "onUpdateSuccess"' (ToolBox/_Info)
        * */
        window.onUpdateSuccess = function () {
            if (!$('.field-validation-error').length) {
                toastr.success("Vos modifications on été mises à jour.");

                var id = $('#Path').val();
                self._refreshTreeView(id);
            }
        }

        $(window).on('TreeView.Select', (e) => self.onSelect(e));

        self.view.find('.js-add').on('click', (e) => self.add(e));
        self.view.find('.js-download').on('click', (e) => self.download(e));
    }

    add(e) {
        e.stopPropagation();

        let self = this;

        let parent = self._getParentFolder(self._getSelected());

        let newItemLbl = "Nouvel élément";
        let parentId = self.treeview.dataItem(parent).id;
        let newItemId = parentId + '/' + newItemLbl;

        self.treeview.append({
            id: newItemId,
            Name: newItemLbl
        }, parent);

        setTimeout(function () {
            // On sélectionne le nouvel élément créé --> Le delay est pour s'assurer que la datasource est bien à jour
            self._selectByNode(self._selectById(newItemId)[0], parentId);
        }, 100);
    }

    getInfo(id, parentId) {
        $.ajax({
            url: '/admin/toolbox/getinfo',
            type: 'GET',
            cache: false, // Cache sur Edge...
            data: {
                id: id,
                basePath: parentId
            },
            success: function (data) {
                $('.js-tree-view-item-info').html(data);
            }
        });
    }

    // Déplacé dans TreeViewItem
    delete(e) {
        let self = this;

        let parent = self.treeview.parent(self._getSelected());

        $.ajax({
            url: '/admin/toolbox/delete',
            type: 'GET',
            cache: false, // Cache sur Edge...
            data: {
                id: self.selectedItem.id
            },
            success: (data) => {
                self.treeview.remove(self.treeview.select());

                // On repositionne sur rien (TODO : le parent serait plus judicieux)
                //$('.js-tree-view-item-info').html('');

                self._refreshTreeView(self.treeview.dataItem(parent).id);
            }
        });
    }

    download(e) {
        e.stopPropagation();

        let self = this;

        let checkedNodes = [];

        self._checkedNodeIds(self.treeview.dataSource.view(), checkedNodes);

        $.ajax({
            url: '/files/generatezip',
            type: 'POST',
            data: {
                paths: checkedNodes
            },
            success: (data, status, xhr) => {
                window.location = '/files/download/?id=' + data.id
                    + '&name=' + data.name + '&mime=' + data.mime;
            }
        });
    }

    _selectByNode(node, parentId) {
        let self = this;

        self.selectedItem = self.treeview.dataItem(node);

        //self.getInfo(self.selectedItem.id, parentId);
    }

    onSelect(e) {
        let self = this;

        self._selectByNode(e.node);
    }

    _getById(id) {
        let self = this;

        var newItem = self.treeview.dataSource.get(id);
        return self.treeview.findByUid(newItem.uid);
    }

    _selectById(id) {
        let self = this;

        let newNode = self._getById(id);
        self.treeview.select(newNode);

        self._selectByNode(newNode);

        return newNode;
    }

    _getSelected() {
        let self = this;

        let selected = self.treeview.select();
        if (!selected.length) {
            self.treeview.select('.k-first');
            selected = self.treeview.select();
        }

        return selected;
    }

    _refreshTreeView(id) {
        let self = this;

        $.when(self.treeview.dataSource.read()).then(function () {
            var paths = self._getExpandPath(id);
            if (!paths.length) {
                return;
            }

            self.treeview.expandPath(paths);

            // Si l'expansion prends trop de temps, et la selection ne peut se faire (car l'item n'exite pas)
            setTimeout(function () {
                self._selectById(id);
            }, 200);
        });
    }

    _getParentFolder(item) {
        let self = this;
        // Seul les folders peuvent avoir un nouvel item
        return self._isFolder(item) ? item : self.treeview.parent(item);
    }

    _isFolder(item) {
        return item.find('.folder').length;
    }

    _getExpandPath(id) {
        let paths = id.split('/');
        let result = [];
        let x = '';
        for (var i = 1; i < paths.length; i++) {
            x = x + '/' + paths[i];
            result.push(x);
        }
        return result;
    }

    _checkedNodeIds(nodes, checkedNodes) {
        let self = this;

        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].checked) {
                checkedNodes.push(nodes[i].id);
            }

            if (nodes[i].hasChildren) {
                self._checkedNodeIds(nodes[i].children.view(), checkedNodes);
            }
        }
    }
}