export default class InOutMapper {

    constructor($view) {
        this.view = $view;
        this.init();
    }

    init() {
        let self = this;

        // On map le contexte des functions sur l'instance
        //self.add = self.updateLinkInOut.bind(self);
        //self.add = self.addLinkInOut.bind(self);
        //self.add = self.removeLinkInOut.bind(self);

        self.view.find('.nav-item').on('click', function (e) {
            self.view.find('.js-mapper').fadeOut(400, function () {
                self.view.find('.output-item.btn-success').removeClass('btn-success');
                $(this).empty().show();
            });
        });

        self.view.find('.output-item').on('click', function (e) {
            e.preventDefault();

            let $this = $(this);

            if ($this.data('type') === 1) {
                return;
            }

            self.view.find('.output-item.btn-success').removeClass('btn-success');
            $this.addClass('btn-success');

            let url = "/outputitemconverters/getall";
            $.ajax({
                type: 'POST',
                url: url,
                data: { id: $this.data('id') },
                success: function (data) {
                    self.view.find('.js-mapper').html(data);
                    // On affiche la colonne qui a été paramétré
                    //self.view.find('.input-items:has(.btn-success)').collapse('show');
                },
                error: function (xhr, ajaxOptions, thrownError) {

                }
            });
        });

        //$('body').on('click', '.input-item .btn', function (e) {
        //    e.preventDefault();

        //    self.updateLinkInOut($(this).parent(), true);
        //});

        //$('body').on('click', '.input-item .badge-danger', function (e) {
        //    e.preventDefault();

        //    self.updateLinkInOut($(this).parent(), false);
        //});

        //$('[data-toggle="tooltip"]').tooltip();
    }

    //updateLinkInOut(item, state) {
    //    let self = this;

    //    let $item = $(item);
    //    let outputItemId = self.view.find('.output-item.btn-success').data('id');

    //    let url = "/configuration/linkinout";
    //    $.ajax({
    //        type: 'POST',
    //        url: url,
    //        data: {
    //            inputItemId: $item.data('id'),
    //            outputItemId: outputItemId,
    //            state: state
    //        },
    //        success: function (data) {
    //            if (data.status) {
    //                if (state) {
    //                    $item.find('.btn').addClass('btn-success');
    //                    $item.find('.btn').removeClass('btn-info');
    //                    $item.find('.badge-danger').removeClass('d-none');
    //                    self.addLinkInOut($item);
    //                } else {
    //                    $item.find('.btn').removeClass('btn-success');
    //                    $item.find('.btn').addClass('btn-info');
    //                    $item.find('.badge-danger').addClass('d-none');
    //                    self.removeLinkInOut($item);
    //                }
    //            }
    //        },
    //        error: function (xhr, ajaxOptions, thrownError) {

    //        }
    //    });
    //}

    //addLinkInOut($item) {
    //    let self = this;

    //    let outputItemId = self.view.find('.output-item.btn-success').data('id');

    //    let url = "/configuration/getnewinputoutputlink";
    //    $.ajax({
    //        type: 'POST',
    //        url: url,
    //        data: {
    //            inputItemId: $item.data('id'),
    //            outputItemId: outputItemId
    //        },
    //        success: function (data) {
    //            self.view.find('.in-out-links-holder').append(data);
    //        },
    //        error: function (xhr, ajaxOptions, thrownError) {

    //        }
    //    });
    //}

    //removeLinkInOut($item) {
    //    let self = this;

    //    self.view.find('.in-out-link[data-inputItemId=' + $item.data('id') + ']').remove();
    //}
}