export default class UploadInput {

    constructor($view) {
        this.view = $view;
        this.init();
    }

    init() {
        let self = this;

        self.view.on('submit', '.wait-form', (e) => {
            // On identifier l'élément a masquer
            let $item = $(self.view.find('.wait-action'));

            // On masque les enfants
            $item.children().hide();

            let loader = $item.find('.loader');
            if (!loader.length) {
                // On insère le loader, s'il n'y en a pas
                $item.append(`<div class='loader'><div class='input'></div></div>`);
            } else {
                // On affiche le loader sinon
                loader.show();
            }
        });
    }
}