export default class TreeViewItem {

    constructor($view) {
        this.view = $view;

        this.init();
    }

    init() {
        let self = this;

        // On map le contexte des functions sur l'instance
        //self.displayFile = self.displayFile.bind(self); // NONEED ?
        //self.displayExtension = self.displayExtension.bind(self); // NONEED ?

        self._displayItem = self._displayItem.bind(self);

        self.view.find('#Document').on('change', function () {
            let v = $(this).val();
            v = v.substr(v.lastIndexOf("/") + 1);

            let x = v.split('.');

            self.view.find('#Name').val(x[0]);
            self.view.find('#Extension').val(x[1]);
            self.view.find('#Extension').blur();

            self.updatePath(self.view.find('#Name'));
        });

        self.view.find('#Name').on('change, blur', function () {
            self.updatePath($(this));
        });

        self.view.find('#Extension').on('change, blur', function () {
            self.displayExtension($(this));
        });

        self.view.find('#IsFolder').on('change', function (e) {
            self.displayFile($(e.target));
        });

        self.displayFile(self.view.find('#IsFolder'));
        self.displayExtension(self.view.find('#Extension'));

        $('#Name').focus().select();
    }

    updatePath($e) {
        let self = this;

        let basePath = $e.data('base-path');
        let path = basePath.substr(0, basePath.lastIndexOf("/")) + "/" + $e.val();

        let extension = self.view.find('#Extension').val();
        if (extension !== '') {
            path += "." + extension;
        }
        self.view.find('#Path').val(path);
    }

    displayFile($e) {
        let self = this;
        self._displayItem(!$e.is(':checked'), '.js-toolbox-item-file');
    }

    displayExtension($e) {
        let self = this;
        let val = $e.val();
        self._displayItem(val !== '' && val !== undefined, '#Extension');
    }

    _displayItem(check, item) {
        let self = this;

        if (check) {
            self.view.find(item).show();
        } else {
            self.view.find(item).hide();
        }
    }
}