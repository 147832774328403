import toastr from 'toastr';

/**
 * Permet d'afficher un petit toast pour prévenir qu'une fonctionnalité n'est pas encore mise en place (évite la frustration de l'utilisateur)
 * 
 * Utilisation : 
 * 
 * Unimplemented.prototype.display();
 * 
 * */
export default class Unimplemented {

    constructor($view) {
        this.view = $view;
        this.init();
    }

    init() {
        let self = this;

        this.view.on('click', function (e) {
            e.preventDefault();

            self.display();
        });
    }

    display() {
        toastr.error("This function is not implemented yet");
    }
}