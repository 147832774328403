export default class HistoryRequest {

    constructor($view) {
        this.view = $view;
        this.init();
    }

    init() {
        let self = this;

        self.view.find('.delete-history').on('click', function (e) {

            var idElement = $(this).data('id');

            let url = "/outputitemconverters/getall";
            $.ajax({
                type: 'POST',
                url: '/historyrequest/cancel',
                data: { id: idElement },
                success: function (data) {

                    if (data.status === 1) {
                        //toastr.success("La demande a bien été annulée.");

                        location.reload();
                    }
                    else {
                        toastr.error("La demande n'a pas pu être annulée.");
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    toastr.error("La demande n'a pas pu être annulée.");
                }
            });


        });
    }
}