function isEmpty(value) {
    return value === null // NULL value
        || value === undefined // undefined
        || value === 'undefined' // undefined
        || value.length === 0 // Array is empty
        ;
}

function isDefault(value) {
    return isEmpty(value)
        || value === 0 // default(int)
        || value === "0" // default(int), en string
        || value === '00000000-0000-0000-0000-000000000000' // default(Guid)
        //|| ((value instanceof Date && !isNaN(value.valueOf())) // Validate DateTime value and check min-max value
        //	&& ((value <= new Date(1753, 01, 01)) // SQL DateTime minimum value
        //	|| (value >= new Date(9999, 12, 31, 23, 59, 59, 999))) // SQL DateTime maximum value
        //);
        ;
}

function xpath(el) {
    if (typeof el == "string") return document.evaluate(el, document, null, 0, null);
    if (!el || el.nodeType != 1) return '';
    if (el.id) return "//*[@id='" + el.id + "']";
    var sames = [].filter.call(el.parentNode.children, function (x) { return x.tagName == el.tagName });
    return xpath(el.parentNode) + '/' + el.tagName.toLowerCase() + (sames.length > 1 ? '[' + ([].indexOf.call(sames, el) + 1) + ']' : '');
}

window.isEmpty = isEmpty;
window.isDefault = isDefault;
window.xpath = xpath;
