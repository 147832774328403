require('bedrock');

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import '@progress/kendo-ui'
import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.filtercell';
import '@progress/kendo-ui/js/kendo.aspnetmvc';

import App from '6tm-components';
window.App = App;

//import 'bootstrap';
import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
//import Globalize from '6tm-globalize';
//import '6tm-globalize/src/cultures/fr-FR';

//window.Globalize = Globalize;

//import 'Respond.js/dest/respond.src'; // Uselfull ?

import toastr from 'toastr';
window.toastr = toastr;


function ImportComponents(components) {
    components.keys().forEach(key => {
        var component = components(key);
        App.Kernel
            .registerComponent(component.default.name, component.default);
    });
}

ImportComponents(require.context('./js/components/', true, /\.(js)$/));
ImportComponents(require.context('./js/pages/', true, /\.(js)$/));

// On s'assure d'attendre que tout soit "ready"
$(() => App.Kernel.bindComponents($("body")));

// Re-bind des components sur les calls ajax
$(document).ajaxSuccess(function () {
    App.Kernel.bindComponents($("body"));
});

import './scss/main.scss';

import './js/utils';
