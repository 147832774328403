/*
 * Class permettant d'afficher le nom du fichier upload dans l'input
 */

export default class CustomFileInput {

    constructor($view) {

        this.view = $view;

        this.Init();
    }

    Init() {
     
        let self = this;

        $(self.view).parents('form').on("click", '.load-on-click', function () {
            $(this).hide();
        });

        $(self.view).on("change", function () {
            let fileNames = $.map($(this)[0].files, (x) => x.name).join('<br/>');
            let label = $(this).siblings(".custom-file-label");

            label.addClass("selected").html(fileNames);
            // On resize
            let height = (((fileNames.match(/<br\/>/g) || []).length + 1) * 24) - 5;
            label.height(height);
            label.parents('.custom-file').height(height + 10);
        });

        $(".remove-image", $(self.view).parents('.form-group')).on("click", function () {
            $(self.view).val("");
            $(self.view).siblings(".custom-file-label").removeClass("selected").html("Aucun fichier selectionné");
            $(self.view).siblings(".custom-file-label").removeClass("selected").html("No file selected");
            $('.image-preview', $(self.view).parents('.form-group')).hide();
        });
    }
}